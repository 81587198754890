import React from 'react';
import { Navigate, Link } from 'react-router-dom'
import { Paper, TextField, Button, CircularProgress, Grid, Typography, InputLabel, Fade, Slide } from '@mui/material';
import { withStyles } from "@mui/styles";
//import { MsalProvider, MsalAuthenticationTemplate } from "@azure/msal-react";
import { authProvider, authFetch, authPost } from './../authProvider';
import { LanguageContext } from './LanguageContext';
import DoneIcon from '@mui/icons-material/Done';

/* Näkymäkohtaiset muuttujat ja vakiot */
const API_PREFIX = 'api/Marketplace';
//const authRequest = {
//    scopes: ["openid", "profile"]
//};

var signedUp = false;

/* TODO:THEME */
const CssTextField = withStyles({
    root: {
        marginBottom: 10,
        '& label.Mui-focused': {
            color: '#7030a0',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#ff6600',
        },
        '& .MuiInputBase-input': {
            color: "#000"
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#ff6600',
            },
            '&:hover fieldset': {
                borderColor: '#ff6600',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#ff6600',
            },
        },
        '& .MuiInputLabel-outlined': {
            color: '#7030a0',
        }
    },
})(TextField);

export class SignUp extends React.Component {
    static contextType = LanguageContext;

    constructor(props) {
        super(props);

        // State alustukset
        this.state = {
            loading: true,
            subscriptions: [],
            user: {},
            userName: null,
            email: null,
            fullName: null,
            company: null,
            companyId: null,
            resolvedSubscription: null,
            isValid: false,
            isSigned: false,
            create: false
        };

    }

    //signup = () => {
    //    const queryParams = new URLSearchParams(window.location.search)
    //    const token = queryParams.get("token")

    //    signedUp = true;

    //    if (token) {
    //        // resolve
    //        authPost(API_PREFIX + '/Subscription/Resolve', {
    //            body: JSON.stringify({ token: token })
    //        })
    //            .then(response => response.json())
    //            .then(data => {
    //                console.log(data); //TODO
    //                var res = data;

    //                if (res.id) {
    //                    // subscriptions
    //                    authFetch(API_PREFIX + '/Subscription/Read/' + res.id)
    //                        .then(response => response.json())
    //                        .then(data => {
    //                            var subs = Array.isArray((data || {}).subscriptions) ? data.subscriptions : [];
    //                            var subsInDb = Array.isArray((data || {}).subsInDb) ? data.subsInDb : [];

    //                            // user details
    //                            authFetch(API_PREFIX + '/User/Read')
    //                                .then(response => response.json())
    //                                .then(data => {
    //                                    console.log(data); //TODO
    //                                    if (typeof (data) === "object") {
    //                                        var isValid = (/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1, 3}\.[0-9]{1, 3}\.[0-9]{1, 3}\.[0-9]{1, 3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g.test(data.email)
    //                                            && (data.fullName || '').length > 0) ? true : false;
                                            
    //                                        this.setState({
    //                                            subscriptions: subs,
    //                                            subsInDb: subsInDb,
    //                                            resolvedSubscription: res,
    //                                            user: data,
    //                                            userName: (data.userName || null),
    //                                            email: (data.email || null),
    //                                            fullName: (data.fullName || null),
    //                                            loading: false,
    //                                            isValid: isValid
    //                                        });
    //                                    } else {
    //                                        this.setState({
    //                                            subscriptions: subs,
    //                                            loading: false
    //                                        });
    //                                    }

    //                                });
    //                        });
    //                } else {
    //                    alert("INVALID TOKEN"); //TODO
    //                }
    //            });
    //    } else {
    //        alert("INVALID TOKEN"); //TODO
    //    }
    //}

    render() {
        const { userLanguage, dictionary } = this.context;

        const handleChange = name => event => {
            if (/^[0-9A-ZÅÖÄa-zåöä!@#$%&*()\s_\-+={[}\]|\:;"',.?\/\\~`]+[0-9A-ZÅÖÄa-zåöä!@#$%&*()\s_\-+={[}\]|\:;"'<,>.?\/\\~`]*$/g.test(event.target.value)) {
                this.setState({ [name]: event.target.value }, () => {
                    const { fullName, email } = this.state;

                    if (/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1, 3}\.[0-9]{1, 3}\.[0-9]{1, 3}\.[0-9]{1, 3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g.test(email)
                        && (fullName || '').length > 0) {
                        this.setState({ isValid: true });
                    } else {
                        this.setState({ isValid: false });
                    }
                });
            }
            else {
                this.setState({ [name]: '', isValid: false });
            }
        }

        const handleSave = (e) => {

            this.setState({ create: true })

            //const { fullName, email, companyId, company, resolvedSubscription } = this.state;
            //const row = (resolvedSubscription || {}).subscription;
            //row.fullName = fullName;
            //row.email = email;
            //row.companyId = companyId;
            //row.company = company;

            //authPost(API_PREFIX + '/Subscription/Create', { body: JSON.stringify(row) })
            //    .then(response => response.json())
            //    .then(data => {
            //        authFetch(API_PREFIX + '/Subscription/Activate/' + (row.id || '') + '/' + (row.planId || ''))
            //            .then(response => response.json())
            //            .then(data => {
            //                this.setState({ isSigned: true });
            //            });
            //    });
        }

        if (this.state.isSigned) {
            return (
                <Navigate to='/' />
            );
        } else {
            return (
                <div style={{ height: "100vh" }}>
                    
                        <Grid container direction="row" justifyContent="center" alignItems="center" style={{ height: "100vh" }} >
                            <Grid item lg={6} md={6} sm={12} xs={12} alignItems="center" style={{ display: 'flex', background: "#fefefe", height: "100vh", justifyContent: "center" }}>
                                {(this.state.create === false) ? (
                                    <div style={{ padding: 10, margin: "auto", background: "#fefefe", width: "85%" }}>
                                        <Typography variant="h4" color="success" sx={{ fontSize: { xs: '26px', md: '36px' } }}>{dictionary.SignUp.Text1[0]}</Typography>
                                        <Typography variant="body1" color="success" sx={{ fontSize: { xs: '16px', md: '26px' } }}>{dictionary.SignUp.Text1[1]}</Typography>

                                        <div style={{ padding: "40px 0px" }}>
                                            <Typography variant="body1" color="success" style={{ padding: "20px 0px 5px 0px" }} sx={{ fontSize: { xs: '15px', md: '18px' } }}>{dictionary.SignUp.FormAccount}</Typography>
                                            <TextField fullWidth placeholder={dictionary.SignUp.Textfield} variant="outlined" value={this.state.userName} required={true} disabled={true} sx={{ fontSize: { xs: '15px', md: '18px' } }} />

                                            <Typography variant="body1" color="success" style={{ padding: "20px 0px 5px 0px" }} sx={{ fontSize: { xs: '15px', md: '18px' } }}>{dictionary.SignUp.FormName}</Typography>
                                            <TextField fullWidth placeholder={dictionary.SignUp.Textfield} variant="outlined" value={this.state.fullName} onChange={handleChange("fullName")} required={true} sx={{ fontSize: { xs: '15px', md: '18px' } }} />

                                            <Typography variant="body1" color="success" style={{ padding: "20px 0px 5px 0px" }} sx={{ fontSize: { xs: '15px', md: '18px' } }}>{dictionary.SignUp.FormEmail}</Typography>
                                            <TextField fullWidth placeholder={dictionary.SignUp.Textfield} variant="outlined" value={this.state.email} onChange={handleChange("email")} required={true} sx={{ fontSize: { xs: '15px', md: '18px' } }} />

                                            <Typography variant="body1" color="success" style={{ padding: "20px 0px 5px 0px" }} sx={{ fontSize: { xs: '15px', md: '18px' } }}>{dictionary.SignUp.FormCompany}</Typography>
                                            <TextField fullWidth placeholder={dictionary.SignUp.Textfield} variant="outlined" value={this.state.company} onChange={handleChange("company")} sx={{ fontSize: { xs: '15px', md: '18px' } }} />

                                            <Typography variant="body1" color="success" style={{ padding: "20px 0px 5px 0px" }} sx={{ fontSize: { xs: '15px', md: '18px' } }}>{dictionary.SignUp.FormCompanyId}</Typography>
                                            <TextField fullWidth placeholder={dictionary.SignUp.Textfield} variant="outlined" value={this.state.companyId} onChange={handleChange("companyId")} sx={{ fontSize: { xs: '15px', md: '18px' } }} />
                                        </div>

                                        <Button fullWidth onClick={handleSave} variant="contained" color="primary" disabled={!this.state.isValid} style={{ height: "60px", fontSize: "18px" }}>{dictionary.SignUp.FormSubmit}</Button>
                                        <div style={{ display: "flex" }}>
                                            <Typography color="success" sx={{ fontSize: { xs: '15px', md: '18px' } }}>{dictionary.SignUp.Text2[0]}</Typography>
                                            <Typography color="primary" sx={{ fontSize: { xs: '15px', md: '18px' } }} style={{ paddingLeft: "10px", textDecoration: "underline" }}>{dictionary.SignUp.Text2[1]}</Typography>
                                        </div>
                                    </div>
                                    ) : (
                                    <Slide direction="down" in={true} timeout={1000}>
                                        <div style={{ padding: 10, margin: "auto", background: "#fefefe", width: "85%" }}>
                                            <div style={{ display: "flex" }}>
                                                <Typography variant="h4" color="error" sx={{ fontSize: { xs: '26px', md: '36px' } }} style={{ padding: "15px 0px" }}>{dictionary.SignUp.Text3[0]}</Typography><DoneIcon style={{ paddingLeft: "15px", fontSize: "64px", color: "#1fbd1c" }} />
                                            </div>
                                            <Typography variant="body1" color="success" sx={{ fontSize: { xs: '16px', md: '26px' } }} style={{ padding: "15px 0px" }}>{dictionary.SignUp.Text3[1]}</Typography>
                                            <Typography variant="body1" color="success" sx={{ fontSize: { xs: '16px', md: '26px' } }} style={{ padding: "15px 0px" }}>{dictionary.SignUp.Text3[2]}</Typography>
                                            <Typography variant="body1" color="success" sx={{ fontSize: { xs: '16px', md: '26px' } }} style={{ padding: "15px 0px" }}>{dictionary.SignUp.Text3[3]}</Typography>
                                            <Typography variant="body1" color="success" sx={{ fontSize: { xs: '16px', md: '26px' } }} style={{ padding: "15px 0px" }}>{dictionary.SignUp.Text3[4]}</Typography>
                                            <div style={{ display: "flex" }}>
                                                <Button component={Link} to="/" fullWidth variant="contained" color="primary" style={{ height: "60px", fontSize: "18px", margin: "15px" }}>{dictionary.SignUp.Text4[0]}</Button>
                                                <Button component={Link} to="/" fullWidth variant="contained" color="secondary" style={{ height: "60px", fontSize: "18px", margin: "15px" }}>{dictionary.SignUp.Text4[1]}</Button>
                                            </div>
                                        </div>
                                    </Slide>)}
                            </Grid>
                            <Grid item lg={6} md={6} sm={0} xs={0} id="login" justifyContent="center" alignItems="center" direction="column" style={{ height: "100vh" }} sx={{ display: { xs: 'none', md: 'flex' } }}>
                                <Typography variant="h4" style={{ color: "#fff" }} sx={{ fontSize: { md: '43px' } }}>{dictionary.SignUp.Text5[0]}</Typography>
                                <Typography variant="h4" style={{ color: "#fff" }} sx={{ fontSize: { md: '43px' } }}>{dictionary.SignUp.Text5[1]}</Typography>
                                <img src={"/images/geomap.png"} />
                            </Grid>
                        </Grid>
                </div>

                //<MsalProvider instance={authProvider}>
                //    <MsalAuthenticationTemplate
                //        interactionType={InteractionType.Redirect}
                //        authenticationRequest={authRequest}
                //    >
                //        <AuthenticatedTemplate>
                //            <div style={{ padding: "10px" }}>
                //                {(!signedUp ? this.signup() : null)}
                //                {(this.state.loading) ?
                //                    (
                //                        <CircularProgress />
                //                    ) :
                //                    (
                //                        (this.state.subsInDb || []).length > 0) ?
                //                        (
                //                            <div style={{ padding: 10, width: 500, margin: "auto", background: "#fefefe" }}>
                //                                <h2>{dictionary.SignUp.SubExistsTitle}</h2>
                //                                <p>{dictionary.SignUp.SubExistsTitle}</p>
                //                                <p>{dictionary.SignUp.SubExistsTitle}</p>
                //                            </div>
                //                        ) :
                //                        (
                //                            <Paper style={{ padding: 10, width: 500, margin: "auto", background: "#fefefe" }} elevation={6}>
                //                                <p><img src={"/images/logo/geoservice_logo.png"} id="logo" width={200} /></p>
                //                                <CssTextField fullWidth label={dictionary.SignUp.FormAccount} variant="outlined" value={this.state.userName} required={true} readOnly={true} />
                //                                <CssTextField fullWidth label={dictionary.SignUp.FormName} variant="outlined" value={this.state.fullName} onChange={handleChange("fullName")} required={true} />
                //                                <CssTextField fullWidth fullWidth label={dictionary.SignUp.FormEmail} variant="outlined" value={this.state.email} onChange={handleChange("email")} required={true} />
                //                                <CssTextField fullWidth label={dictionary.SignUp.FormCompany} variant="outlined" value={this.state.company} onChange={handleChange("company")} />
                //                                <CssTextField fullWidth label={dictionary.SignUp.FormCompanyId} variant="outlined" value={this.state.companyId} onChange={handleChange("companyId")} />
                //                                <Button fullWidth onClick={handleSave} variant="contained" color="primary" disabled={!this.state.isValid}>{dictionary.SignUp.FormSubmit}</Button>
                //                                <p style={{ color: "#000" }}>{dictionary.SignUp.FormMessage}</p>
                //                            </Paper>
                //                        )
                //                }
                //            </div>
                //        </AuthenticatedTemplate>
                //        <UnauthenticatedTemplate>
                //            <p>{dictionary.SignUp.Unauthenticated}</p>
                //        </UnauthenticatedTemplate>
                //    </MsalAuthenticationTemplate >
                //</MsalProvider>
            );
        }
    }
}
