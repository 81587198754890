import React, { Component } from 'react';
import { Link as ScrollLink, scroller } from 'react-scroll';
import { Link, Redirect, useLocation } from 'react-router-dom';
import { LanguageContext } from './LanguageContext';
import { languageOptions } from './../translations';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';

/* TODO:THEME */
const MenuTypo = (props) => {
    return <Typography textAlign="center" style={{ color: "#303030" }} > {props.children}</Typography>;
}

/* TODO:THEME */
const MenuButton = (props) => {
    return <Button size="large" onClick={props.onClick} style={{ fontWeight: "bold", fontSize: "17pt" }}>{props.children}</Button>;
}

export class HelpNavMenu extends Component {
    static contextType = LanguageContext;

    constructor(props) {
        super(props);

        this.state = {
            collapsed: true,
            navTransparent: true,
            path: null,
            anchorElNav: null,
            anchorElUser: null
        };

    }

    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state, callback) => {
            return;
        };
    }

    handleLogEvent = (e, tar, act, info, url) => {
        e.preventDefault();

        fetch('api/EventLog/Create', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                target: tar,
                action: act,
                info: info
            })
        })
    }

    render() {
        const { userLanguage, userLanguageChange, dictionary } = this.context;
        const { anchorElNav, anchorElUser } = this.state;

        const handleLanguageChange = (event) => {
            this.setState({ anchorElUser: null });
            userLanguageChange((event.currentTarget.dataset || {}).lang)
        };

        const handleOpenUserMenu = (event) => {
            this.setState({ anchorElUser: event.currentTarget });
        };

        const handleCloseUserMenu = () => {
            this.setState({ anchorElUser: null });
        }

        return (
            <AppBar color="primary" elevation={3}>
                <Container maxWidth="xl">
                    <Toolbar disableGutters>
                        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }} >
                            <Link to="/"><img src={"/images/logo/gesli-logo-horizontal-transparent-background.svg"} id="logo" width="200px" style={{ cursor: "pointer" }} /></Link>
                        </Box>
                        <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }} >
                            <Link to="/"><img src={"/images/logo/gesli-logo-horizontal-transparent-background.svg"} id="logo" width={100} style={{ cursor: "pointer" }} /></Link>
                        </Box>

                        <Box sx={{ flexGrow: 0 }}>
                            <MenuButton onClick={handleOpenUserMenu}>{dictionary.displayName}</MenuButton>
                            <Menu
                                sx={{ mt: '45px' }}
                                id="menu-appbar"
                                anchorEl={anchorElUser}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={Boolean(anchorElUser)}
                                onClose={handleCloseUserMenu}

                            >
                                {
                                    Object.entries(languageOptions).map(([id, name]) => (
                                        <MenuItem key={id} data-lang={id} onClick={(e) => { handleLanguageChange(e); this.handleLogEvent(e, id, "Kielen vaihto", "Navmenu"); window.location.href = "/Blog" }}>
                                            <MenuTypo>{name}</MenuTypo>
                                        </MenuItem>
                                    ))
                                }
                            </Menu>
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>
        );
    }
}
