import React, { Component } from 'react';
import { LanguageContext } from '../../LanguageContext';
import { Link, Redirect, useLocation } from 'react-router-dom';
import { Container, Typography, Button, Grid } from '@material-ui/core';

export class FirstBlogFi extends Component {
    static contextType = LanguageContext;

    render() {
        const { userLanguage, dictionary } = this.context;

        return (
            <div style={{ backgroundColor: "#fff", minHeight: "100vh" }}>
                <div style={{ padding: "10px" }}>
                    <Button color="primary" variant="contained" onClick={event => window.location.href = "/blog_" + userLanguage}>Takaisin</Button>
                </div>
                <Container maxWidth="md" style={{ padding: "10px 65px", borderLeft: "2px solid rgba(112, 48, 160, 0.5)", borderRight: "2px solid rgba(112, 48, 160, 0.5)" }}>
                    <div style={{ paddingBottom: "75px" }}>
                        <img width="100%" alt="text" src="/images/blog/wireframe_v1.webp" />
                        <Grid container direction="row" style={{ padding: "10px 0px" }}>
                            <Grid lg={10} md={10} sm={10} xs={10}>
                                <p style={{ color: "#7030a0", fontSize: "22pt" }}>Palvelu avataan pian</p>
                            </Grid>
                            <Grid lg={2} md={2} sm={2} xs={2} style={{ paddingTop: "10px" }}>
                                <Typography variant="body2" style={{ color: "#303030ff", padding: "0px 0px 0px 5px", fontSize: "15pt", textAlign: "right" }} component="div">Mikko Lähdeaho</Typography>
                                <Typography variant="body2" style={{ color: "#303030ff", padding: "0px 0px 0px 5px", fontSize: "15pt", textAlign: "right" }} component="div">Feb 1, 2022</Typography>
                            </Grid>
                        </Grid>
                        <hr style={{ border: "1px solid rgba(112, 48, 160, 0.5)" }}/>
                        <p style={{ color: "#303030ff", paddingBottom: "10px", fontSize: "15pt" }} >
                            StarCareer-palvelun tilaussivu aukeaa pian...
                        </p>
                    </div>
                </Container>
            </div>
        );
    }
}