import React, { Component } from 'react';
import { Link, Redirect, useLocation } from 'react-router-dom';
import { Link as ScrollLink, scroller } from 'react-scroll';
import { NavMenu } from './NavMenu';
import { LanguageContext } from './LanguageContext';
import CookiePopup from "./CookiePopup";

import { KeyboardArrowUp, CheckCircle } from '@mui/icons-material';
import { withStyles } from "@mui/styles";

import {
    Grid, Fab, Typography, TextField, Button, Zoom, Container,
    Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
    Card, CardActions, CardContent, List, ListItem, ListItemText, Box, ListItemIcon, Fade, Stack
} from '@mui/material';

const ScrollFab = (props) => {
    return (<Zoom in={true}><Fab onClick={props.onClick} size="small" color="primary" style={{ color: "#fff", position: 'fixed', bottom: "10px", right: "10px" }}>{props.children}</Fab></Zoom>);
}

/* TODO:THEME */
const PlanCard = (props) => {
    return (
        <Grid item>
            <Box
                style={{
                    backgroundColor: "#fff",
                    boxShadow: "0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12)",
                    transitionDuration: "250ms",
                    minWidth: 320,
                    maxWidth: 320,
                    borderRadius: "20px"
                }}
                sx={{
                    '&:hover': {
                        boxShadow: "none !important",
                    }
                }}
            >
                <CardContent style={{ backgroundColor: props.color, color: props.textColor, textAlign: "center", borderRadius: "20px", minHeight: 600, }} >
                    <Typography component="h4" variant="h4" style={{ fontSize: "34px" }}>{props.title}</Typography>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "15px 0px" }}>
                        <Typography variant="h3" sx={{ fontSize: '16pt' }}>{props.price}</Typography>
                        <Typography variant="h3" sx={{ fontSize: '16pt' }}>{props.unit}</Typography>
                    </div>
                    <List>
                        {props.features.map(x => {
                            return (
                                <ListItem key={x}>
                                    <ListItemIcon>
                                        <CheckCircle style={{ color: "#ff6600" }} />
                                    </ListItemIcon>
                                    <ListItemText>
                                        <Typography style={{ fontSize: '16pt', color: props.color2 }}>{x}</Typography>
                                    </ListItemText>
                                </ListItem>)
                        })}
                    </List>
                </CardContent>
                <CardActions style={{ justifyContent: "center", paddingBottom: 15 }}>
                    <Button variant="contained" color="primary" onClick={props.handler}>{props.btn}</Button>
                </CardActions>
            </Box>
        </Grid>
    );
}

const ContactCard = ({ children }) => (
    <Grid item lg={4} md={12} >
        <Box
            style={{
                backgroundColor: "#fff",
                boxShadow: "0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12)",
                transitionDuration: "250ms",
                minWidth: 300,
                minHeight: 400,
                borderRadius: "20px"
            }}
            sx={{
                '&:hover': {
                    boxShadow: "none !important",
                }
            }}
        >
            <CardContent style={{ backgroundColor: "#fff", color: "#000", textAlign: "center", borderRadius: "20px" }} >
                {children}
            </CardContent>
        </Box>
    </Grid>
)

//TODO:THEME
const GrayTextField = withStyles({
    root: {
        '& label.Mui-focused': {
            color: '#303030ff',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#303030ff',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#303030ff',
            },
            '&:hover fieldset': {
                borderColor: '#303030ff',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#303030ff',
            },
        },
        '& .MuiInputLabel-outlined': {
            color: '#303030ff',
        },
        '& .MuiInputBase-root': {
            color: '#303030ff',
        }
    }
})(TextField);

export class Home extends Component {
    static contextType = LanguageContext;

    constructor(props) {
        super(props);

        this.handleScroll = this.handleScroll.bind(this);

        this.state = {
            loading: false,
            showFab: false,
            name: null,
            email: null,
            message: null,
            messageOpen: false,
            orderOpen: false,
            company: null,
            companyid: null,
            orderComplete: false,
            isFree: false,
            orderMessage: null,
            items: [0, 1, 2, 3],
            item: 0,
            intervalId: 0
        };
        window.addEventListener('scroll', this.handleScroll);

    }

    componentDidMount() {
        const newIntervalId = setInterval(() => {
            if (this.state.item > 2) {
                this.setState({ item: 0 })
            }
            else {
                this.setState(prevState => {
                    return {
                        ...prevState,
                        item: prevState.item + 1,
                    };
                });
            }
        }, 15000);

        this.setState(prevState => {
            return {
                ...prevState,
                intervalId: newIntervalId,
            };
        });
    }

    componentWillUnmount() {
        clearInterval(this.state.intervalId);
    }

    handleScroll = (e) => {
        if (window.scrollY >= 100) {
            this.setState({ showFab: true });
        }
        else {
            this.setState({ showFab: false });
        }
    }

    handleScrollTo = (e, par) => {
        e.preventDefault();

        scroller.scrollTo(par, {
            smooth: true,
            duration: 700,
            spy: true
        })

    }

    OpenNewWindow = (e) => {
        e.preventDefault();

        var url = null
        fetch('api/Login/GetLink')
            .then(response => response.text())
            .then(data => {
                url = data;
                if (url) {
                    window.open(url, '_blank').focus();
                }
            });

    }

    handleLogEvent = (e, tar, act, info, url) => {
        e.preventDefault();

        fetch('api/EventLoger/Create', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                target: tar,
                action: act,
                info: info
            })
        })

        if (url) {
            window.open(url, '_blank').focus();
        }
    }

    handleMessage = (actionName) => {
        const { name, email, message } = this.state;
        fetch('api/Message/Create', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                name: name,
                action: actionName,
                email: email,
                message: message,
                isValid: this.state.isValid,
                messageOpen: false
            })
        })
            .then(response => response.json())
            .then(data => {
                if (data === "OK") {
                    this.setState({
                        name: "",
                        email: "",
                        message: "",
                        messageOpen: true,
                        isValid: false
                    });
                }
            })
    }

    handleOrder = (e, actionName) => {
        e.preventDefault();

        const { name, email, orderMessage, company, companyid } = this.state;
        fetch('api/Message/Order', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                name: name,
                action: actionName,
                email: email,
                message: orderMessage,
                company: company,
                companyId: companyid,
                isValid: this.state.isValid
            })
        })
            .then(response => response.json())
            .then(data => {
                if (data === "OK") {
                    this.setState({
                        name: "",
                        email: "",
                        orderMessage: "",
                        company: "",
                        companyid: "",
                        orderOpen: false,
                        orderComplete: true
                    });
                }
            })
    }

    render() {
        const { userLanguage, dictionary } = this.context;

        const handleChange = name => event => {

            if (/^[0-9A-ZÅÖÄa-zåöä!@#$%&*()\s_\-+={[}\]|\:;"',.?\/\\~`]+[0-9A-ZÅÖÄa-zåöä!@#$%&*()\s_\-+={[}\]|\:;"'<,>.?\/\\~`]*$/g.test(event.target.value)) {
                this.setState({ [name]: event.target.value }, () => {
                    const { name, email, companyid, isFree, orderOpen } = this.state;
                    // Message and Orders
                    if (/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1, 3}\.[0-9]{1, 3}\.[0-9]{1, 3}\.[0-9]{1, 3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g.test(email)
                        && (
                            (orderOpen && (
                                (/^[\d]{7}-[\d]$/g.test(companyid) && !isFree) || // Paid Order
                                isFree // Free Order
                            ))
                            || (!orderOpen && !isFree) // Message
                        )
                        && (name || '').length > 0) {
                        this.setState({ isValid: true });
                    } else {
                        this.setState({ isValid: false });
                    }
                });
            }
            else {
                this.setState({ [name]: '', isValid: false });
            }
        }

        const handleClose = name => event => {
            this.setState({ [name]: false });

            this.setState({ name: "", email: "", message: "", company: "", companyid: "", isValid: false, isFree: false })
        };

        return (
            <div>
                <NavMenu />
                <div >
                    {/*** Home position: "absolute", bottom: "10px", justifyContent: "center" ***/}
                    <section id="Home" style={{ minHeight: "100vh", display: "flex" }}>
                        <Box sx={{ top: 140 }} style={{ position: 'absolute', left: 20, textAlign: 'left' }}>
                            <div>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    sx={{ fontSize: '16pt' }}
                                    style={{ margin: "0px 5px", width: 250 }}
                                    onClick={(e) => { this.OpenNewWindow(e); this.handleLogEvent(e, "Login", "Click", "Etusivu") }}
                                >
                                    {dictionary.Home.Login}
                                </Button>
                            </div>
                            <div
                                style={{ marginTop: 10 }}
                            >
                                <Button
                                    component={Link}
                                    to="/signup"
                                    color="text"
                                    variant="outlined"
                                    sx={{ fontSize: '16pt' }}
                                    style={{ margin: "0px 5px", backgroundColor: "#00000099", width: 250 }}
                                >
                                    {dictionary.Home.SignUp}
                                </Button>
                            </div>
                        </Box>

                        <div style={{ position: 'absolute', bottom: 5, right: 20, textAlign: 'right' }}>
                            <Typography sx={{ fontSize: '36px' }} color="primary">{dictionary.Home.Text1}</Typography>
                            <Typography sx={{ fontSize: '26px', backgroundColor: "#ffffff32" }} color="error">{dictionary.Home.Text2}</Typography>
                            <Typography sx={{ fontSize: '26px', backgroundColor: "#ffffff32" }} color="error">{dictionary.Home.Text3}</Typography>
                        </div>
                    </section>

                    {/*** Features ***/}
                    <section id="Features" style={{ minHeight: "100vh", paddingTop: 250 }}>
                        <Container maxWidth="xl">
                            <Grid
                                container
                                spacing={8}
                                direction="row"
                                justifyContent="center"
                                alignItems="flex-end"
                            >
                                <Grid item lg={4} md={12} style={{ paddingBottom: 20 }}>
                                    <Box
                                        style={{ width: "100%" }}
                                    >
                                        <img
                                            src="/images/kartta-1.webp"
                                            width="100%"
                                        />
                                        <Box>
                                            <Box>
                                                <Typography sx={{ fontSize: '16pt' }} color="text.secondary">
                                                    {dictionary.Features.Text1}
                                                </Typography>
                                            </Box>
                                            <Box>
                                                {/*href="/help"*/}
                                                <Button sx={{ fontSize: '16pt' }} >{dictionary.Features.Button1}</Button>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item lg={4} md={12} style={{ paddingBottom: 20 }}>
                                    <Box
                                        style={{ width: "100%" }}
                                    >
                                        <img
                                            src="/images/api-1.webp"
                                            width="100%"
                                        />
                                        <Box>
                                            <Box>
                                                <Typography sx={{ fontSize: '16pt' }} color="text.secondary">
                                                    {dictionary.Features.Text2}
                                                </Typography>
                                            </Box>
                                            <Box>
                                                {/*href="/help"*/}
                                                <Button sx={{ fontSize: '16pt' }} >{dictionary.Features.Button1}</Button>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item lg={4} md={12} style={{ paddingBottom: 20 }}>
                                    <Stack spacing={2}>
                                        <Card sx={{ display: 'flex', borderRadius: "20px" }}>
                                            <Box sx={{ minWidth: 150, padding: 1 }}>
                                                <img
                                                    src="/images/globe-1.webp"
                                                    width="150px"
                                                />
                                            </Box>
                                            <Box sx={{ padding: 1 }}>
                                                <Box>
                                                    <Typography sx={{ fontSize: '13pt' }} color="text.secondary">
                                                        {dictionary.Features.Text3}
                                                    </Typography>
                                                </Box>
                                                <Box>
                                                    {/*href="/help"*/}
                                                    <Button sx={{ fontSize: '16pt' }}>{dictionary.Features.Button1}</Button>
                                                </Box>
                                            </Box>
                                        </Card>
                                        <Card sx={{ display: 'flex', borderRadius: "20px" }}>
                                            <Box sx={{ minWidth: 150, padding: 1 }}>
                                                <img
                                                    src="/images/abc-1.webp"
                                                    width="150px"
                                                />
                                            </Box>
                                            <Box sx={{ padding: 1 }}>
                                                <Box>
                                                    <Typography sx={{ fontSize: '13pt' }} color="text.secondary">
                                                        {dictionary.Features.Text4}
                                                    </Typography>
                                                </Box>
                                                <Box>
                                                    {/*href="/help"*/}
                                                    <Button sx={{ fontSize: '16pt' }}>{dictionary.Features.Button1}</Button>
                                                </Box>
                                            </Box>
                                        </Card>
                                        <Card sx={{ display: 'flex', borderRadius: "20px" }}>
                                            <Box sx={{ minWidth: 150, padding: 1 }}>
                                                <img
                                                    src="/images/business-1.webp"
                                                    width="150px"
                                                />
                                            </Box>
                                            <Box sx={{ padding: 1 }}>
                                                <Box>
                                                    <Typography sx={{ fontSize: '13pt' }} color="text.secondary">
                                                        {dictionary.Features.Text5}
                                                    </Typography>
                                                </Box>
                                                <Box>
                                                    {/*href="/help"*/}
                                                    <Button sx={{ fontSize: '16pt' }} >{dictionary.Features.Button1}</Button>
                                                </Box>
                                            </Box>
                                        </Card>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Container>
                    </section>

                    {/*** Prices ***/}
                    <section id="Prices" style={{ minHeight: "100vh", paddingTop: 150 }}>
                        <Container maxWidth="xl">
                            <div style={{ textAlign: "center" }}>
                                <Typography sx={{ fontSize: '36px' }} color="primary">{dictionary.Prices.MainTitle}</Typography>
                                <Typography sx={{ fontSize: '16pt' }} color="error">{dictionary.Prices.Text1}</Typography>
                            </div>
                            <div style={{ marginTop: "20px" }}>
                                <Grid container spacing={8} style={{ justifyContent: "center" }}>
                                    <PlanCard color="#fff" color2="#000" textColor="#000" title={dictionary.Prices.Plan[0].title} features={dictionary.Prices.Plan[0].features} price="0" unit={dictionary.Prices.Plan[0].unit} btn={dictionary.Prices.Plan[3].unit} handler={(e) => this.handleScrollTo(e, "Contact")} />
                                    <PlanCard color="#fff" color2="#000" textColor="#000" title={dictionary.Prices.Plan[1].title} features={dictionary.Prices.Plan[1].features} price="15" unit={dictionary.Prices.Plan[1].unit} btn={dictionary.Prices.Buy} handler={() => this.setState({ orderOpen: true })} />
                                    <PlanCard color="#fff" color2="#000" textColor="#000" title={dictionary.Prices.Plan[2].title} features={dictionary.Prices.Plan[2].features} price="149" unit={dictionary.Prices.Plan[2].unit} btn={dictionary.Prices.Buy} handler={() => this.setState({ orderOpen: true })} />
                                    <PlanCard color="#fff" color2="#000" textColor="#000" title={dictionary.Prices.Plan[3].title} features={dictionary.Prices.Plan[3].features} price="" unit={dictionary.Prices.Plan[3].unit} btn={dictionary.Prices.Plan[3].unit} handler={(e) => this.handleScrollTo(e, "Contact")} />
                                </Grid>

                                <div style={{ paddingTop: 20 }}>
                                    <Typography sx={{ fontSize: '16pt' }} color="error">{dictionary.Prices.TaxNotice}</Typography>
                                </div>
                            </div>
                        </Container>
                    </section>

                    {/*** Contact ***/}
                    {/* TODO:THEME */}
                    <section id="Contact" style={{ minHeight: "100vh", paddingTop: 150 }}>
                        <Container maxWidth="xl">
                            <div style={{ textAlign: "center" }}>
                                <Typography color="primary" style={{ fontSize: '36px' }}>{dictionary.Contact.MainTitle}</Typography>
                            </div>
                            <Grid container spacing={3} style={{ textAlign: "center", marginTop: 40 }}>
                                <ContactCard>
                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "15px 0px" }}>
                                        <Typography style={{ fontSize: "36px" }}>{dictionary.Contact.SupportFab}</Typography>
                                    </div>
                                    <List>
                                        <Typography style={{ fontSize: "16pt" }}>{dictionary.Contact.TextA1}</Typography>
                                        <Typography style={{ color: "#ED6C02", fontSize: "16pt" }}>{dictionary.Contact.TextA2}</Typography>
                                        <Typography style={{ fontSize: "16pt" }}>{dictionary.Contact.TextA3}</Typography>
                                        <Typography style={{ color: "#ED6C02", fontSize: "16pt" }}>{dictionary.Contact.TextA4}</Typography>
                                    </List>
                                </ContactCard>
                                <ContactCard>
                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "15px 0px" }}>
                                        <Typography sx={{ fontSize: "36px" }}>{dictionary.Contact.ContactFab}</Typography>
                                    </div>
                                    <List>
                                        <Typography style={{ fontSize: "16pt" }}>{dictionary.Contact.TextB1}</Typography>
                                        <Typography style={{ color: "#ED6C02", fontSize: "16pt" }}>{dictionary.Contact.TextB2}</Typography>
                                        <Typography style={{ fontSize: "16pt" }}>{dictionary.Contact.TextB3}</Typography>
                                        <Typography style={{ color: "#ED6C02", fontSize: "16pt" }}>{dictionary.Contact.TextB4}</Typography>
                                    </List>
                                </ContactCard>
                                <ContactCard>
                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "15px 0px" }}>
                                        <Typography style={{ fontSize: "36px" }}>{dictionary.Contact.ContactFormFab}</Typography>
                                    </div>
                                    <List>
                                        <TextField
                                            onChange={handleChange("name")}
                                            required={true}
                                            value={this.state.name || ""}
                                            label={dictionary.Contact.FormName}
                                            variant="outlined"
                                            fullWidth
                                            style={{ paddingBottom: 5 }} />
                                        <TextField
                                            onChange={handleChange("email")}
                                            required={true}
                                            value={this.state.email || ""}
                                            label={dictionary.Contact.FormEmail}
                                            variant="outlined"
                                            fullWidth
                                            style={{ paddingBottom: 5 }} />
                                        <TextField
                                            onChange={handleChange("message")}
                                            required={true}
                                            value={this.state.message || ""}
                                            label={dictionary.Contact.FormMessage}
                                            variant="outlined"
                                            fullWidth
                                            multiline
                                            maxRows={5}
                                            style={{ paddingBottom: 5 }}
                                        />
                                        <Fab
                                            disabled={!this.state.isValid}
                                            variant="extended"
                                            style={{ backgroundColor: (this.state.isValid ? "#ff6600" : "#ccc"), color: "#fff", fontWeight: "bold", fontSize: "15pt", width: "250px", margin: 5 }}
                                            onClick={(e) => {
                                                this.handleLogEvent(e, "SendMessage", "Click", "Etusivu");
                                                this.handleMessage(dictionary.Message.ActionName);
                                            }}
                                        >{dictionary.Contact.SendFab}</Fab>
                                    </List>
                                </ContactCard>
                                {/*        </CardContent>*/}
                                {/*    </Box>*/}
                                {/*</Grid>*/}
                                {/*<Grid item lg={1} md={0} sm={0} xs={0} />*/}
                            </Grid>

                            <Grid container style={{ textAlign: "center", paddingTop: "30px" }}>
                                <Grid item lg={12} md={12} sm={12} xs={12} style={{ paddingBottom: "15px" }}>
                                    © Startecon Oy 2023
                                </Grid>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <a href="https://www.gesli.fi" >{"www.gesli.fi"}</a> |&nbsp;
                                    <a href="https://www.startecon.fi" >{"www.startecon.fi"}</a> |&nbsp;
                                    <Link to="" onClick={(e) => this.handleLogEvent(e, "PrivacyPolicy", "Click", "Etusivu", "/privacypolicy_" + userLanguage)}>{dictionary.Contact.PrivacyPolicy}</Link> |&nbsp;
                                    <Link to="" onClick={(e) => this.handleLogEvent(e, "TermsAndConditions", "Click", "Etusivu", "/terms_" + userLanguage)} >{dictionary.Contact.Terms}</Link> | {dictionary.Contact.CompanyId}
                                </Grid>
                            </Grid>
                        </Container>
                    </section>
                </div >

                {/*** MessageDialog ***/}
                < Dialog open={this.state.messageOpen} onClose={handleClose("messageOpen")} >
                    <DialogTitle>
                        <Typography component="h2" variant="h2" style={{ fontSize: "26pt" }}>
                            {dictionary.Message.Title}
                        </Typography>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <Typography variant="subtitle1" style={{ fontSize: "15pt", verticalAlign: "text-top" }}>
                                {dictionary.Message.TextBody}
                            </Typography>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" color="secondary" onClick={handleClose("messageOpen")} style={{ color: "#fff", fontWeight: "bold", fontSize: "17pt" }}>{dictionary.Message.OK}</Button>
                    </DialogActions>
                </Dialog >

                {/*** OrderDialog ***/}
                < Dialog open={this.state.orderComplete} onClose={handleClose("orderComplete")} >
                    <DialogTitle>
                        <Typography component="h2" variant="h2" style={{ fontSize: "26pt" }}>
                            {dictionary.Message.OrderTitle}
                        </Typography>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <Typography variant="subtitle1" style={{ fontSize: "15pt", verticalAlign: "text-top" }}>
                                {dictionary.Message.TextBody}
                            </Typography>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" color="secondary" onClick={handleClose("orderComplete")} style={{ color: "#fff", fontWeight: "bold", fontSize: "17pt" }}>{dictionary.Message.OK}</Button>
                    </DialogActions>
                </Dialog >

                {/*** OrderForm ***/}
                < Dialog open={this.state.orderOpen} onClose={handleClose("orderOpen")} >
                    <DialogTitle>
                        <Typography component="h2" variant="h2" style={{ fontSize: "26pt" }}>
                            {dictionary.Message.OrderActionName}
                        </Typography>
                    </DialogTitle>
                    <DialogContent>
                        <GrayTextField
                            onChange={handleChange("name")}
                            value={this.state.name}
                            required={true}
                            label={dictionary.Contact.FormName}
                            variant="outlined"
                            fullWidth
                            style={{ paddingBottom: 10, color: "#000" }} />
                        <GrayTextField
                            onChange={handleChange("email")}
                            value={this.state.email}
                            required={true}
                            label={dictionary.Contact.FormEmail}
                            variant="outlined"
                            fullWidth
                            style={{ paddingBottom: 10, color: "#000" }} />
                        <GrayTextField
                            onChange={handleChange("company")}
                            value={this.state.company}
                            label={dictionary.Contact.FormCompany}
                            variant="outlined"
                            required={!this.state.isFree}
                            fullWidth
                            multiline
                            maxRows={5}
                            style={{ paddingBottom: 10, color: "#000" }}
                        />
                        <GrayTextField
                            onChange={handleChange("companyid")}
                            value={this.state.companyid}
                            label={dictionary.Contact.FormCompanyId}
                            variant="outlined"
                            required={!this.state.isFree}
                            fullWidth
                            multiline
                            maxRows={5}
                            style={{ paddingBottom: 10, color: "#000" }}
                        />
                        <GrayTextField
                            onChange={handleChange("orderMessage")}
                            value={this.state.orderMessage}
                            label={dictionary.Contact.OrderMessage}
                            variant="outlined"
                            fullWidth
                            multiline
                            maxRows={5}
                            style={{ paddingBottom: 10, color: "#000" }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button disabled={!this.state.isValid} variant="contained" color="secondary" onClick={(e) => this.handleOrder(e, dictionary.Message.OrderActionName)} style={{ backgroundColor: (this.state.isValid ? "#ff6600" : "#ccc"), color: "#fff", fontWeight: "bold", fontSize: "15pt", width: "250px", margin: 5 }}>{dictionary.Contact.SendFab}</Button>
                    </DialogActions>
                </Dialog >

                <CookiePopup />

                {(this.state.showFab) ? (<ScrollFab color="primary" onClick={(e) => this.handleScrollTo(e, "Home")}><KeyboardArrowUp /></ScrollFab>) : (<React.Fragment></React.Fragment>)}
            </div >
        );
    }
}