import React from 'react';
import { LanguageContext } from './LanguageContext';
import Container from '@mui/material/Container';
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import rehypeRaw from 'rehype-raw'
import CircularProgress from '@mui/material/CircularProgress';
import { Link as ScrollLink, scroller } from 'react-scroll';
import { HelpNavMenu } from "./HelpNavMenu";
import SearchIcon from '@mui/icons-material/Search';
import { Grid, Paper, Typography, TextField, Button, Tooltip, CardMedia, InputLabel, Input, FormControl, Fade, Link } from '@mui/material';
import _ from "lodash";
/* Näkymäkohtaiset muuttujat ja vakiot */
const API_PREFIX = 'api/Markdown';


const headers = (props) => {
    const { level, children } = props;
    const heading = children[0];
    var anchor = (typeof heading === 'string') ? heading.toLowerCase() : '';
    anchor = anchor.replace(/ä/g, 'a');
    anchor = anchor.replace(/ö/g, 'o');
    anchor = anchor.replace(/[^a-zA-Z0-9 ]/g, '');
    anchor = anchor.replace(/ /g, '-');
    return (<div id={anchor}>
        {level == 1 ? (<h1>{children}</h1>) :
            level == 2 ? (<h2>{children}</h2>) :
                level == 3 ? (<h3>{children}</h3>) :
                    (<h4>{children}</h4>)}
    </div>);
}

const searchParams = new URLSearchParams(document.location.search);

var updatedList;

export class Help extends React.Component {
    static contextType = LanguageContext;

    constructor(props) {
        super(props);

        this.state = {
            selected: null,
            loading: true,
            showIndex: true //oletuksena esitetään sisällysluettelo
        };

    }

    componentDidMount() {
        fetch(API_PREFIX + '/Index/' + this.context?.userLanguage)
        .then(response => response.json())
        .then(data => {
            this.setState({
                data: _.cloneDeep(data),
                filteredData: data,
                structure: data,
                markdown: null,
                loading: false,
                showIndex: true,
            }, () => {
                const id = searchParams.get('id')
                if (id) {
                    this.handleSelect(null, id);

                    if (id.includes("#")) {
                        var res = id.split('#')[1];
                        console.log(res)
                        scroller.scrollTo(res, {
                            smooth: true,
                            duration: 700,
                            spy: true
                        })
                    }
                }
            });
        });
    }

    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state, callback) => {
            return;
        };
    }

    // Haetaan markdown tiedosto
    handleSelect = (event, value) => {
        value = value + ".md"
        fetch(API_PREFIX + '/Read', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ id: value, lang: this.context.userLanguage })
        })
            .then(response => response.text())
            .then(data => {
                this.setState({
                    markdown: data,
                    selected: value,
                    showIndex: false
                });
            });
        document.documentElement.scrollTo({ top: 0, behavior: "instant" });
    }

    handleBack = () => {
        window.location.href = "/Help";
        document.documentElement.scrollTo({ top: 0, behavior: "instant" });
    }

    handleHashLink = (event, value) => {
        fetch(API_PREFIX + '/Read', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ id: value, lang: this.context.userLanguage })
        })
            .then(response => response.text())
            .then(data => {
                this.setState({
                    markdown: data,
                    selected: value,
                    showIndex: false
                });
            });
    }

    change = (e) => {
        var query = e.target.value;

        if (query == '') {
            this.setState({ filteredData: _.cloneDeep(this.state.data) });
            console.log(updatedList, this.state.data, this.state.filteredData)
        }
        else {
            updatedList = this.state.filteredData

            let result = updatedList.children.filter((i) => {
                return (i.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
            });

            updatedList.children = result
            this.setState({ filteredData: updatedList });
        }
    }

    render() {
        const { userLanguage, userLanguageChange, dictionary } = this.context;
        const { structure, filteredData } = this.state;

        // Lista blogeista eli sisällysluettelo
        const bloglist = () => {
            return ((filteredData || {}).children || []).map((d, i) => {
                return (
                    <Grid item lg={4} md={4} sm={6} xs={12} style={{ textAlign: 'left' }}>
                        <Paper key={i} style={{ color: "#000", padding: "20px", marginBottom: "8px", maxWidth: "750px", minHeight: "530px", border: "solid 1px #d5d5d5" }}>
                            <p><img src={d.image} alt={d.name} style={{ width: "100%", textAlign: "center" }} /></p>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <p style={{ textAlign: "left", width: "75%", fontWeight: "bold" }}>{d.name}</p>
                                <p style={{ textAlign: "right" }}>{d.date}</p>
                            </div>
                            <p style={{ textAlign: "left" }}>{d.description}</p>
                            <Button style={{ color: "#fff", textAlign: "left", fontWeight: "bold", background: "#7030a0ff" }} variant="outlined" onClick={() => this.handleSelect(null, d.id)}>{dictionary.Blog.Btn}</Button>
                        </Paper>
                    </Grid>
                );
            });
        }

        return (
            <React.Fragment>
                <HelpNavMenu />
                <div style={{ backgroundColor: "#fff", marginTop: "130px" }}>
                    {(this.state.loading) ? (
                        <CircularProgress />
                    ) : (
                        <Container maxWidth="xl">
                            <FormControl sx={{ m: 1 }} variant="standard">
                                <Input
                                    id="standard-adornment-amount"
                                    onChange={(e) => this.change(e)}
                                    startAdornment={<SearchIcon position="start" color="iconText">$</SearchIcon>}
                                    style={{ width: "360px" }}
                                />
                            </FormControl>
                            <Grid container spacing={4} direction="row" justifyContent="space-evenly" alignItems="center">
                                {this.state.showIndex ? bloglist() : (
                                    <Paper style={{ color: "#000", padding: "20px", maxWidth: "750px", border: "solid 1px #d5d5d5", marginTop: "25px" }}>
                                        <ReactMarkdown
                                            children={this.state.markdown}
                                            remarkPlugins={[remarkGfm]}
                                            rehypePlugins={[rehypeRaw]}
                                            components={{
                                                a: (props) => {
                                                    return props.href.startsWith('http') ?
                                                        (<a target="_blank" href={props.href}>{props.children}</a>) : // External links
                                                        props.href.startsWith('#') ?
                                                            (<Link
                                                                component="button"
                                                                variant="p"
                                                                onClick={() => {
                                                                    const el = document.getElementById(props.href.slice(1))
                                                                    if (el) el.scrollIntoView();
                                                                }}
                                                            >
                                                                {props.children}
                                                            </Link>) : // Same page links
                                                            (<Link
                                                                component="button"
                                                                variant="h3"
                                                                onClick={() => {
                                                                    this.handleSelect(null, props.href);
                                                                }}
                                                            >
                                                                {props.children}
                                                            </Link>);  // Render internal links with Link component

                                                },
                                                h2: (props) => {
                                                    return (
                                                        <h1 style={{ marginBottom: 0 }}>{props.children}</h1>
                                                    );
                                                },
                                                p: (props) => {
                                                    return (
                                                        <p style={{ marginBottom: 0 }}>{props.children}</p>
                                                    );
                                                },
                                                a: (props) => {
                                                    return (
                                                        <a href={props.children} style={{ color: "blue" }}>{props.children}</a>
                                                    );
                                                },
                                                img: (props) => {
                                                    const src = (props.src.indexOf('ClientApp/public') > -1 ? props.src.substring(props.src.indexOf('ClientApp/public') + 16) : props.src);
                                                    return (
                                                        <img src={src} style={{ maxWidth: "100%" }} alt={props.alt} />
                                                    );
                                                }
                                            }}
                                        />
                                            <Button style={{ color: "#fff", textAlign: "left", fontWeight: "bold", background: "#7030a0ff", marginTop: 20 }} variant="outlined" onClick={(e) => { this.handleBack(e) }}>{dictionary.Blog.Btn2}</Button>
                                    </Paper>
                                )}
                            </Grid>
                        </Container>
                    )}
                </div>
            </React.Fragment>
        );
    }
}


/*
<Page>
                                    Paluu sisällykseen eli esitetään sisällysluettelo
                                    <div style={{ paddingLeft: "25px", paddingTop: "10px" }}>
                                        <Button style={{ color: "#fff", fontWeight: "bold", background: "#7030a0ff" }} variant="outlined" onClick={(e) => { window.location.href = "/Blog" }}>{dictionary.Blog.Btn2}</Button>
                                    </div>

                                    <Content className={"help-content"}>
 </Content>
                                </Page>


 */