import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { registerServiceWorker, unregister } from './registerServiceWorker';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const themeOptions = {
    palette: {
        type: 'light',
        primary: {
            main: '#ED6C02',
        },
        secondary: {
            main: '#1E1E1E',
        },
        error: {
            main: '#000000',
        },
        success: {
            main: '#1E1E1ECC',
        },
        warning: {
            main: '#D9D9D9',
        },
        info: {
            main: '#fff',
        },
        text: {
            main: '#fff',
        },
    },
    typography: {
        fontFamily: 'Poppins',
    },
    props: {
        MuiTooltip: {
            arrow: true,
        },
    },
};

const theme = createTheme(themeOptions);

theme.typography.body1 = {
    fontWeight: 400,
    //[theme.breakpoints.up('xs')]: {
    //    lineHeight: "24px"
    //},
    //[theme.breakpoints.up('md')]: {
    //    lineHeight: "42px"
    //},
};

theme.typography.h4 = {
    fontWeight: 600,
    [theme.breakpoints.up('xs')]: {
        padding: "5px 0px 20px 0px",
    },
    [theme.breakpoints.up('md')]: {
        lineHeight: "41.99px",
        padding: "10px 0px 20px 0px",
        letterSpacing: "0.25px"
    },
};

theme.typography.h5 = {
    fontWeight: 400,
    [theme.breakpoints.up('xs')]: {
        lineHeight: "25px"
    },
    [theme.breakpoints.up('md')]: {
        lineHeight: "32px"
    },
};

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

ReactDOM.render(
    <BrowserRouter basename={baseUrl}>
        <ThemeProvider theme={theme}>
            <App />
        </ThemeProvider>
    </BrowserRouter>,
    rootElement);

//registerServiceWorker();
unregister();

